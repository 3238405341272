<!--手机密码登录-->
<template>
  <div>
    <el-row>
      <el-form :model="form" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="nickname">
          <el-input placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" type="text"
                    v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input placeholder="请输入密码" prefix-icon="el-icon-unlock" type="password" show-password
                    v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row style="text-align: left;margin: 10px auto">
      <el-checkbox v-model="consent" label="1">阅读并接受<span style="color: #5587f8"
                                                               @click="exemption = true">免责声明</span>及<span
          @click="exemption = true" style="color: #5587f8">服务条款</span>
      </el-checkbox>
    </el-row>
    <el-row style="margin: 30px auto">
      <button class="login" @click="doLogin">登录</button>
      <slot/>
    </el-row>


    <el-dialog
        custom-class="custom-dialog"
        width="868px"
        title="服务协议"
        :visible.sync="exemption">
      <div class="mz">
        <el-scrollbar style="height: 575px;padding-bottom: 0px">
          <h1>免责声明及服务条款</h1>
          <p>
            欢迎使用我们公司的登录界面。在使用本登录界面之前，请仔细阅读以下免责声明及服务条款。通过访问或使用本登录界面，即表示您同意接受以下免责声明及服务条款的所有条款和条件。如果您不同意这些条款和条件，请不要使用本登录界面。</p>
          <h2>1. 免责声明</h2>
          <p>
            我们公司致力于提供准确和及时的信息，但不对本登录界面的信息的准确性、完整性或及时性做出任何明示或暗示的声明或保证。</p>
          <h2>2. 技术限制</h2>
          <p>
            尽管我们采取了合理的技术措施来确保本登录界面的安全性，但我们无法保证本登录界面的无错误、安全或不受干扰。因此，我们不对任何由于您使用本登录界面而可能造成的任何损失或损害承担责任。</p>
          <h2>3. 外部链接</h2>
          <p>
            本登录界面可能包含到第三方网站的链接。这些链接仅供方便参考。我们对任何第三方网站的内容或隐私做法不承担责任。访问任何链接网站均由您自行承担风险。</p>
          <h2>4. 知识产权</h2>
          <p>
            本登录界面及其内容受版权法保护。未经我们事先书面许可，任何人不得复制、修改、传播或以任何方式利用本登录界面的内容。</p>
          <h2>5. 用户责任</h2>
          <p>
            您应对使用本登录界面的行为和后果负全部责任。您同意不会利用本登录界面进行非法活动或违反任何适用法律、规定或政策。</p>
          <h2>6. 修改和解释</h2>
          <p>
            我们保留随时修改本免责声明的权利，并将在本页面上发布任何更改。您应定期查看本页面以了解任何更新或修改。对于本免责声明的解释，我们保留最终决定权。</p>
          <h2>7. 服务条款</h2>
          <p>
            通过使用本登录界面，您同意遵守我们公司的服务条款。我们保留随时修改服务条款的权利，并将在本页面上发布任何更改。您应定期查看本页面以了解任何更新或修改的服务条款。</p>
          <p>如果您对本免责声明及服务条款有任何疑问或需要进一步解释，请联系我们公司的客户服务部门。</p>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exemption = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as req from "@/utils/request";
import {setToken} from "@/utils/token/jwtService";

export default {
  data() {
    return {
      form: {
        nickname: null,
        password: null
      },
      consent: false,
      exemption: false,
      rules: {
        nickname: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {

    doLogin() {
      // 校验手机号
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (!this.consent) {
            this.$message.warning('请阅读并接受免责声明及服务条款')
            return
          }
          let obj = this.form
          let sendData = {}
          sendData.data = obj
          sendData.fn = this._loginFn
          sendData.vm = this
          req.Post('phone/doLogin', sendData)
        } else {
          this.$message.error('请输入正确信息')
        }
      })
    },
    _loginFn(data) {
      if (!data) return
      if (data.code === 200) {
        setToken(data.data)
        this.$message.success('登录成功')
        this.$router.push('/index')
        return
      }
      this.$message.error(data.message)
    }
  }
}
</script>
<style scoped>
@import "@/assets/from.css";

/deep/ .el-input__inner {
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  font-family: 微软雅黑;
  font-weight: bold;
}


.mz {
  font-family: Arial, sans-serif;
  text-align: left;
  border-radius: 6px;
}

.mz h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mz h2 {
  font-size: 18px;
  margin-bottom: 8px;
}

.mz p {
  margin-bottom: 6px;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

>>> .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 150px);
  max-width: calc(100% - 30px);
  border-radius: 12px;
}

>>> .el-dialog .el-dialog__body {
  flex: 1;
  overflow: hidden;
}
</style>
