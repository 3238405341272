<template>
  <div>
    <b-container class="order-index">
      <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 20px" class="mt-4">
        <el-breadcrumb-item><span style="cursor: pointer" @click="$router.push('/Index')">
          首页
        </span></el-breadcrumb-item>
        <el-breadcrumb-item><a class="font-weight-bold text-dark">订单详情</a></el-breadcrumb-item>
      </el-breadcrumb>
      <b-row class="mt-3">
        <div class="left-img-icon"></div>
        <div class="left-img-text">
          确认订单信息
        </div>
      </b-row>


      <div class="card-box">
        <b-row>
          <div class="card-box-in">
            购买账号:
            <div class="d-flex ml-4">

              <el-avatar id="avatar" shape="square" size="medium"
                         src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
              <span class="ml-2">
              {{ getUserName }}
          </span>
              <span class="small-wechat">(微信登录)</span>
            </div>
          </div>
<!--          <span class="switchAccounts" @click="switchAccounts">更换账号</span>-->
        </b-row>

        <b-row>
          <div class="row-small">
            注意：购买后不支持退款、转让，请确认开课时间或有效期后再提交订单
          </div>
        </b-row>

        <b-row class="mt-3">
          <el-divider></el-divider>
        </b-row>


        <b-row class="mt-3">
          <div class="card-box-in">
            支付方式:
            <div class="d-flex ml-4">

              <!--              <el-image style="width: 30px;height: 40px" :src="require('@/assets/image/zfbplay.png')"></el-image>-->
              <el-image class="w-75" :src="require('@/assets/image/wechatplay.png')"></el-image>
            </div>
          </div>
        </b-row>


        <b-row class="mt-3">
          <el-divider></el-divider>
        </b-row>


        <b-row class="mt-3">
          <div class="card-box-in">
            购买课程:
          </div>
        </b-row>

        <b-row class="mt-2" v-for="(item,index) in orderList" :key="index">
          <div class="aboutUs justify-content-lg-between
          justify-content-md-between justify-content-sm-center
          d-block d-md-block d-sm-block d-lg-flex">
            <el-image
                class="companyPresentation"
                :src="require('@/assets/image/radar-img.png')"
                fit="fill"></el-image>
            <div class="text-infos ml-lg-5 mt-3">
<!--              <el-image :src="require('@/assets/image/alltext.png')"></el-image>-->

              <div class="m-4">
                <b-row class="p-2">
                  <div class="header-title">课程概述</div>
                </b-row>

                <b-row class="p-2">
                  <div class="left-img-text-small">
                    本课程适合所有零基础，以及想要掌握雷达的学员。
                  </div>
                </b-row>

                <b-row class="p-2">
                  <div class="left-img-text-small">
                    【购买提示】
                  </div>
                </b-row>


                <b-row class="p-2">
                  <div class="left-img-text-small">
                    <!--            1. 本课程为视频授课，共12课时+12课堂讲义，不提供发票；-->
                    1. 本课程购买有效期为24小时，可自行安排时间进行学习。
                  </div>
                </b-row>

                <!--        <b-row class="p-2">-->
                <!--          <div class="left-img-text-small">-->
                <!--            2. 本课程购买有效期为24小时，可自行安排时间进行学习。-->
                <!--          </div>-->
                <!--        </b-row>-->
              </div>
              <div style="  float: right;">
                <div class="small-amount mt-2">
                  ¥ {{ item.amount }}
                </div>

<!--                <el-popover-->
<!--                    placement="top"-->
<!--                    width="160"-->
<!--                    v-model="visible">-->
<!--                  <p>请输入优惠码:</p>-->
<!--                  <div style="text-align: right; margin: 0">-->
<!--                    <el-input class="pb-3" size="mini"></el-input>-->
<!--                    <el-button type="primary" size="mini" @click="visible = false">确定</el-button>-->
<!--                  </div>-->
<!--                  <el-link class="mt-2 ml-1" slot="reference" type="primary">优惠码兑换</el-link>-->
<!--                </el-popover>-->

              </div>
            </div>
          </div>
        </b-row>

      </div>
      <div id="right-context-box" class="mt-4">
        <div id="right-context-left">
          <span>实付</span>
          <p>¥{{ getTheAmount }}</p>
        </div>
        <div class="d-flex mb-3  ml-auto d-flex justify-content-end">
          <div class="mt-1">
           <span style="font-size: 14px;color: rgba(55, 55, 55, 1)">
              <el-checkbox v-model="protocol" label="1">勾选协议表示同意</el-checkbox>
           </span>
            <el-button type="text" @click="protocolShow = true">《航海练习课堂用户付费协议》</el-button>
          </div>
          <div class="js-btn" @click="participation" v-loading.fullscreen.lock="fullscreenLoading">
            立即购买
          </div>
        </div>
      </div>

    </b-container>


    <el-dialog
        title="购买"
        :visible.sync="productsShow"
        width="40%">
      <div class="qr-class" v-if="qrCodeImgUrl">
        <div>
          <img width="40%" height="40%" :src="qrCodeImgUrl">
        </div>
        <div>
          <span>打开微信扫一扫支付</span>
        </div>
      </div>
      <div v-if="payWasSuccessful">
        <el-result icon="success" title="支付成功" subTitle="请根据提示进行操作">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="productsShow = false">返回</el-button>
          </template>
        </el-result>
      </div>
      <span slot="footer" class="dialog-footer" v-if="qrCodeImgUrl">
        <el-button type="primary" @click="closeProduct">取消支付</el-button>
      </span>
    </el-dialog>

    <el-dialog
        custom-class="custom-dialog"
        width="868px"
        title="航海练习课堂用户付费协议"
        :visible.sync="protocolShow">
      <div class="mz">
        <el-scrollbar style="height: 605px;padding-bottom: 0px">
<!--          <h1>航海练习课堂用户付费协议</h1>-->
          <p>欢迎使用我们的航海练习课堂。在使用本课堂之前，请仔细阅读以下用户付费协议。通过访问或使用本课堂，即表示您同意接受以下用户付费协议的所有条款和条件。如果您不同意这些条款和条件，请不要使用本课堂。</p>

          <h2>1. 服务描述</h2>
          <p>航海练习课堂提供的服务内容包括但不限于航海知识学习、模拟航行训练、航海技能测试等。</p>

          <h2>2. 用户付费</h2>
          <p>
            <strong>费用：</strong> 用户需支付一定金额以获得航海练习课堂的服务。<br>
            <strong>支付方式：</strong> 用户可以使用信用卡、微信支付等方式进行支付。<br>
          </p>

          <h2>3. 使用条款</h2>
          <p>
            <strong>许可：</strong> 用户支付后获得对航海练习课堂的访问权限，仅限个人学习使用，不得转让给他人。<br>
            <strong>禁止行为：</strong> 用户不得利用课堂服务进行违法活动，不得侵犯他人知识产权，不得发布违反法律法规或社会公共道德的内容。
          </p>

          <h2>4. 责任限制</h2>
          <p>
            航海练习课堂提供者尽最大努力保证服务的安全性和稳定性，但不对因不可抗力、技术故障或其他不可控因素导致的服务中断或信息丢失承担责任。
          </p>

          <h2>5. 知识产权</h2>
          <p>航海练习课堂及其内容受版权法和其他相关法律法规保护，未经许可，用户不得擅自复制、传播或利用课堂内的知识产权内容。</p>

          <h2>6. 协议更新</h2>
          <p>航海练习课堂提供者保留随时更新用户付费协议的权利，更新后的协议将在课堂网站上公布，用户应定期查阅并遵守最新协议。</p>

          <h2>7. 其他条款</h2>
          <p>
            如果用户对本用户付费协议有任何疑问或需要进一步解释，请联系航海练习课堂提供者的客户服务部门。
          </p>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="protocolShow = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as req from "@/utils/request";
import QRCode from "qrcode";
import netty from "@/utils/websocket";

export default {
  name: "orderIndex",
  data() {
    return {
      protocolShow: false,
      qrCodeImgUrl: null,
      payWasSuccessful: false,
      productsShow: false,
      fullscreenLoading: false,
      visible: false,
      ws: null,
      protocol: false,
      orderList: []
    }
  },
  watch: {
    'productsShow'(show) {
      if (!show) {
        // 解决关闭瞬间显示表格 优化
        setTimeout(() => {
          this.qrCodeImgUrl = null
          this.payWasSuccessful = false
          if (this.ws) {
            this.ws.closeNetty()
          }
        }, 200)

      } else {
        // this.queryProductList()
      }
    }
  },
  computed: {
    // 获取金额
    getTheAmount() {
      let list = this.orderList
      let amount = 0
      for (let item in list) {
        amount += list[item].amount
      }
      return amount.toFixed(2)
    },
    getUserName() {
      let sysuser = window.$global.userDetails
      if (!sysuser) {
        return ''
      }
      if (!sysuser) return null

      return sysuser.sysUser.nickname
    },
  },
  created() {
    let params = this.$route.params.productList
    if (!params) {
      this.$router.push('/index')
      return
    }
    this.orderList = params
  },
  methods: {
    // 取消支付
    closeProduct() {
      this.productsShow = false
    },
    // 获取支付二维码
    payment(out_trade_no) {
      let sendData = {}
      let obj = {out_trade_no}
      sendData.data = obj
      sendData.fn = this.paymentFn
      sendData.vm = this
      req.Post('wxPay/payment', sendData)
    },
    paymentFn(data) {
      if (!data) return
      this.fullscreenLoading = false
      if (data.code === 200 && data.data) {
        this.productsShow = true
        this.payWasSuccessful = false
        // 使用toDataURL方法 返回的是promise对象 通过.then取值
        let img = QRCode.toDataURL(data.data.code_url)
        img.then(t => {
          this.qrCodeImgUrl = t
        })
        // 使用 订单号 out_trade_no 作为唯一索引 防止 用户重复登录对象被覆盖的问题
        this.ws = new netty(data.data.out_trade_no)
            .createWebSocket((data) => {
              if (data.code === 200) {
                this.qrCodeImgUrl = null
                this.payWasSuccessful = true
                this.$message.success('购买成功')
                this.$router.push('/student')
                this.protocol = false
                // 清空购物车 todo 这边应该由服务器处理
                window.$global.shoppingCart = []
              } else if (data.code === 3006) {
                this.qrCodeImgUrl = null
                this.productsShow = false
                this.payWasSuccessful = false
                this.$message.error(data.message)
              }
            })
        return
      }
      this.$message.error(data.message)
    },
    /**
     * 下单
     * @params id 产品id
     * */
    placingAnOrder(id) {
      let sendData = {}
      let obj = {id}
      sendData.data = obj
      sendData.fn = this.placingAnOrderFn
      sendData.vm = this
      req.Post('wxPay/placeOrder', sendData)
    },
    placingAnOrderFn(data) {
      if (!data) return
      if (data.code === 200) {
        // this.$message.success('下单成功')
        this.payment(data.data)
        return;
      }
      this.fullscreenLoading = false
      this.$message.error(data.message)
    },
    // 打开充值弹出显示二维码
    participation() {
      if (!this.protocol) {
        this.$message.warning('未同意协议')
        return
      }
      this.fullscreenLoading = true
      // this.$router.push('/shopping')
      // 后端需要实现 商品不存在 禁止下单
      this.placingAnOrder(this.orderList[0].id)
    },
    switchAccounts() {
      this.$message.warning('待开发')
    }
  }
}
</script>

<style scoped>
.order-index {
  width: 100%;
  height: 100%;
}


.left-img-icon {
  width: 23px;
  height: 23px;
  background-size: 100%;
  margin: 7px 15px;
  background-image: url("@/assets/image/left-img-icon.png");
}

.left-img-text {
  padding: 0 5px;
  font-weight: bold;
  font-size: 25px;
  display: flex;
}

.card-box-in {
  font-weight: bold;
  font-size: 23px;
  display: flex;
}

.card-box {
  width: 100%;
  height: 100%;
  padding: 64px 71px 25px 64px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.20) 0 0 4px 0px;
}

.small-wechat {
  font-size: 15PX;
  text-align: end;
  display: flex;
  margin: 7px 15px;
  color: rgba(150, 150, 150, 1);
}

.row-small {
  font-size: 20PX;
  font-weight: unset;
  color: rgba(150, 150, 150, 1);
}


.aboutUs {
  width: 100%;
  align-items: center;
  text-align: left;
  margin: 0 auto;
}

.companyPresentation {
  max-width: 429px;
  display: block;
  max-height: 398px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.text-infos {
  max-width: 600px;
  min-width: 400px;
  width: 100%;
}

#right-context-box {
  font-weight: bold;
    width: 100%;
  justify-content: flex-end;
  padding-bottom: 30px;
}

#right-context-left {
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}


#right-context-left p {
  margin: -10px 20px;
  color: red;
  font-size: 24px;
}

#avatar {
  position: relative;
  bottom: 3px;
}

#right-context-left span {
  font-size: 13px;
  margin: 3px -10px;
}

.js-btn {
  width: 113px;
  height: 49px;
  float: right;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  color: white;
  line-height: 45px;
  font-family: 微软雅黑;
  cursor: pointer;
  font-size: 16px;
  background-image: url("@/assets/image/golden.png");
}

.small-amount {
  font-size: 20px;
  color: rgba(55, 55, 55, 1);
  font-weight: bold;
}

.switchAccounts {
  height: 20px;
  font-size: 14px;
  margin-left: auto;
  margin-top: 15px;
  color: hsla(209, 100%, 45%, 1);
  line-height: 10px;
  border-bottom: hsla(209, 100%, 45%, 1) solid 1px;
  cursor: pointer;
}

.qr-class {
  display: block;
  justify-content: center;
  text-align: center;
}


.mz {
  font-family: Arial, sans-serif;
  text-align: left;
  border-radius: 6px;
}

.mz h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mz h2 {
  font-size: 18px;
  margin-bottom: 8px;
}

.mz p {
  margin-bottom: 6px;
}


>>> .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 150px);
  max-width: calc(100% - 30px);
  border-radius: 12px;
}

>>> .el-dialog .el-dialog__body {
  flex: 1;
  overflow: hidden;
}
</style>
