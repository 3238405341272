<!--用户注册-->
<script>
import * as req from "@/utils/request";

export default {
  name: 'registeredUsers',
  data() {
    return {
      form: {
        phone: null,
        password: null,
        openid: null,
        code: null,
        uuid: null
      },
      number: '获取验证码',
      whetherToScanTheCode: false,
      // 确认密码
      password: null,
      rules: {
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          //校验手机号的正则：/^1[3456789]\d{9}$/
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号.",
            trigger: "blur",
          },
        ]
      }
    }
  },
  created() {
    this.wechatQRCodeLogin()
    window.$startTimer = this.countdown
  },
  methods: {
    countdown() {
      this.number = 60
      let timer = setInterval(() => {
        this.number--
        if (this.number <= 0) {
          clearInterval(timer)
          this.number = '获取验证码'
        }
      }, 1000)
    },
    // 微信扫码注册
    wechatQRCodeLogin() {
      let sendData = {}
      sendData.data = null
      sendData.fn = this._wechatQRCodeLoginFn
      sendData.vm = this
      req.Get('wechat/qr/register', sendData)
    },
    _wechatQRCodeLoginFn(data) {
      if (!data) {
        this.$message.error('网络连接失败 请稍后重试')
        return
      }
      if (data.code === 200) {
        // eslint-disable-next-line no-undef
        var obj = new WxLogin({
          self_redirect: false,
          id: 'weixinRegister', // 需要显示的容器id
          appid: data.data.appid, // 公众号appid wx*******
          scope: data.data.scope, // 网页默认即可
          redirect_uri: data.data.redirect_uri, // 授权成功后回调的url
          state: data.data.state, // 可设置为简单的随机数加session用来校验
          style: 'black', // 提供"black"、"white"可选。二维码的样式
          href: '', // 外部css文件url，需要https
          fast_login: 0
        })
        return;
      }
      this.$message.error(data.message)
    },

    // 获取验证码
    sendSms() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$parent.sendSms(this.form)
        } else {
          this.$message.error('请输入正确信息')
          return false;
        }
      })
    },
    registeredUsers() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {

          if (this.form.password || this.password) {
            if (this.form.password !== this.password) {
              this.$message.error('两次密码输入不一致')
              return
            }
          }
          if (this.form.code == null) {
            this.$message.error('验证码不能为空！')
            return
          }
          let sendData = {}
          sendData.data = this.form
          sendData.fn = this._registeredUsersFn
          sendData.vm = this
          req.Post('sysUser/register', sendData)
        } else {
          this.$message.error('请输入正确信息')
          return false;
        }
      })
    },
    _registeredUsersFn(data) {
      if (data.code === 200) {
        this.$parent.mode = 'phone'
        this.$message.success('注册成功')
        this.form = {
          phone: null,
          password: null,
          openid: null,
          code: null,
          uuid: null
        }
        this.wechatQRCodeLogin()
        return
      }

      this.$message.error(data.message)
    },
  }
}
</script>

<template>
  <div class="row-move-r">
    <div v-if="!whetherToScanTheCode && form.uuid">
      <el-row>
        <el-form :model="form" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" type="text"
                      v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="请输入密码(选填)" prefix-icon="el-icon-unlock" type="code"
                      show-password
                      v-model="form.password"></el-input>
          </el-form-item>
          <!-- v-if="form.password"         -->
          <el-form-item>
            <el-input placeholder="请再次输入密码" prefix-icon="el-icon-unlock" type="code"
                      show-password
                      v-model="password"></el-input>
          </el-form-item>

          <el-form-item prop="code">
            <el-input placeholder="请输入验证码" prefix-icon="el-icon-chat-line-round" type="code"
                      v-model="form.code"></el-input>
          </el-form-item>

          <el-button :disabled="number !== '获取验证码'" class="getCode" @click="sendSms">
            {{ number >= 60 ? '获取验证码' : number }}
          </el-button>
        </el-form>
      </el-row>
      <el-row style="margin: 0px auto">
        <button class="login" @click="registeredUsers">注册</button>
        <slot/>
      </el-row>
    </div>

    <section class="wechat-qr2" v-else>
      <div class="custom-qr-text">
        微信扫一扫注册
        <div class="tab-line">&nbsp;</div>
      </div>
      <div id="weixinRegister">
      </div>
    </section>
  </div>
</template>

<style scoped>
@import "@/assets/from.css";

/deep/ .el-input__inner {
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  font-family: 微软雅黑;
  font-weight: bold;
}

.row-move-r {
  width: 85% !important;
  margin: 0px auto !important;
  height: 70%;
}

.wechat-qr2 {
  overflow: hidden !important;
  width: 100%;
  height: 92%;
  min-height: 330px;
}

#weixinRegister {
  overflow: hidden !important;
  width: 100%;
  height: 92%;
  min-height: 330px;
}

.custom-qr-text {
  height: 34px;
  width: 350px;
  position: absolute;
  background-color: white;
  font-size: 18px
}

.tab-line {
  width: 40px;
  background-color: #4074E1;
  height: 2.5px;
  border-radius: 12px;
  margin: 6px auto;
  box-shadow: #4074E1 0 0px 30px 0px;
  transition: all .1s linear;
}

</style>
