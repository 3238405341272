<!-- 找回密码-->
<script>
import * as req from "@/utils/request";

export default {
    name: 'retrieveYourPassword',
    data() {
        return {
            form: {
                phone: null,
                password: null,
                code: null,
                confirmThePassword: null
            },
            number: '获取验证码',
            timer: null,
            rules: {
                phone: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'},
                    //校验手机号的正则：/^1[3456789]\d{9}$/
                    {
                        pattern: /^1[3456789]\d{9}$/,
                        message: "请输入正确的手机号",
                        trigger: "blur",
                    },
                ]
            }
        }
    },
    methods: {
        countdown() {
            this.number = 60
            this.timer = setInterval(() => {
                this.number--
                if (this.number <= 0) {
                    clearInterval(this.timer)
                    this.number = '获取验证码'
                }
            }, 1000)
        },
        // 获取验证码
        sendSms() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    let sendData = {}
                    sendData.data = this.form
                    sendData.fn = this._sendSmsFn
                    sendData.vm = this
                    req.Post('code/verify', sendData)
                } else {
                    this.$message.error('请输入正确信息')
                    return false;
                }
            })
        },
        _sendSmsFn(data) {
            if (!data) return
            if (data.code === 200) {
                this.$message.success('发送成功')
                this.countdown()
                return;
            }
            this.$message.error(data.message)
        },
        updatePassword() {
            if (this.form.password !== this.form.confirmThePassword) {
                this.$message.error('两次密码输入不一致')
                return
            }

            if (this.form.code == null) {
                this.$message.error('验证码为空！')
                return;
            }
            let sendData = {}
            sendData.data = this.form
            sendData.fn = this._updatePasswordFn
            sendData.vm = this
            req.Post('sysUser/updatePassword', sendData)
        },
        _updatePasswordFn(data) {
            if (data.code === 200) {
                // 个人信息 编辑成功
                this.$message.success('修改成功')
                if (this.$route.name === 'Setting') {
                    clearInterval(this.timer)
                    this.number = '获取验证码'
                    window.$getUserInfo()
                    this.form = {
                        phone: null,
                        password: null,
                        code: null,
                        confirmThePassword: null
                    }
                    this.$emit('change', true)
                    return;
                }
                this.$parent.mode = 'phone'
                return
            }
            this.$message.error(data.message)
        },
    }
}
</script>

<template>
    <div>
        <el-row>
            <el-form :model="form" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item prop="phone">
                    <el-input placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" type="text"
                              v-model="form.phone"></el-input>
                </el-form-item>

                <el-form-item prop="password">
                    <el-input placeholder="请输入密码" prefix-icon="el-icon-unlock" type="text"
                              show-password
                              v-model="form.password"></el-input>
                </el-form-item>
                <el-form-item prop="confirmThePassword">
                    <el-input placeholder="请再次输入密码" prefix-icon="el-icon-unlock" type="text"
                              show-password
                              v-model="form.confirmThePassword"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="请输入验证码" prefix-icon="el-icon-chat-line-round" type="code"
                              v-model="form.code"></el-input>
                </el-form-item>
                <el-button class="getCode" :disabled="number !== '获取验证码'" @click="sendSms">{{ number }}</el-button>
            </el-form>
        </el-row>
        <el-row>
            <button class="login" @click="updatePassword">确定</button>
        </el-row>
    </div>
</template>

<style scoped>
@import "@/assets/from.css";

/deep/ .el-input__inner {
    border-radius: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    font-family: 微软雅黑;
    font-weight: bold;
}
</style>
