import jwtDefaultConfig from './jwtDefaultConfig'


// jwtConfig <= Will be used by this service
let jwtConfig = {...jwtDefaultConfig}

function onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
    this.subscribers.push(callback)
}

function getToken() {
    return localStorage.getItem(jwtConfig.storageTokenKeyName)
}

function getRefreshToken() {
    return localStorage.getItem(jwtConfig.storageRefreshTokenKeyName)
}

function setToken(value) {
    localStorage.setItem(jwtConfig.storageTokenKeyName, value)
}

function setRefreshToken(value) {
    localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, value)
}


export {
    onAccessTokenFetched,
    addSubscriber,
    getToken,
    getRefreshToken,
    setToken,
    setRefreshToken
}
