import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './plugins/bootstrap-vue'
import {VueGlobal} from "@/global"

Vue.config.productionTip = false
// 全局变量
let global = new VueGlobal()
window.$global = global

// 路由动画
import('@/assets/style/router.css')
// 定义混入变量
Vue.mixin({
    data() {
        return {
            global: window.$global
        }
    }
})
router.afterEach((to, from, next) => {
// 示例：
    setTimeout(()=> {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        },0)
    })

})
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
