<template>
    <b-container direction="vertical" class="shopping-container">
        <b-row class="mt-3">
            <div class="global-title">我的购物车</div>
            <span class="text-small">共{{ shoppingCart.length }}门课程</span>
        </b-row>
        <el-table
                :summary-method="getSummaries"
                show-summary
                class="mt-2"
                ref="multipleTable"
                border
                style="width: 100%"
                :data="shoppingCart"
                :header-cell-style="{backgroundColor: '#F0F0F0',color: '#373737'}"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange">
            <el-table-column
                    type="selection"
                    width="65">
            </el-table-column>
            <el-table-column
                    label="课程名称"
                    width="430">
                <template slot-scope="scope">{{ scope.row.name }}</template>
            </el-table-column>
            <el-table-column
                    prop="expirationDate"
                    align="center"
                    width="250"
                    label="学期或课程有效期">
              {{ new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleString() }}
            </el-table-column>
            <el-table-column
                    prop="amount"
                    label="金额(元)"
                    width="150"
                    align="center"
                    show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                    align="center"
                    label="操作">
                <template slot-scope="scope">
                  <el-popconfirm
                      title="确定删除当前商品吗？"
                      @confirm="deleteItem(scope.$index)"
                  >
                    <i slot="reference" class="el-icon-delete" style="font-size: 20px;color: #0075E6"></i>
                    <el-button type="danger">删除</el-button>
                  </el-popconfirm>

                    <!--            <el-button icon="el-icon-delete" @click="deleteItem(scope.row)"></el-button>-->
                </template>
            </el-table-column>
        </el-table>


        <div id="totalColumn">
            <div>
                <div id="left-context">
                    <span>合计</span>
                    <p>¥{{ getAmount }}</p>
                </div>
                <span class="bottom-span">
          （若购买享有优惠，相应金额将在订单结算页面扣减）
        </span>
            </div>
            <div class="jsBtn" @click="settlement">
                去结算
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "hgShopping",
    data() {
        return {
            selectionList: []
        }
    },
    computed: {
        getAmount() {
            let list = this.selectionList
            let res = 0.00;
            for (let i in list) {
                res += list[i].amount
            }
            return res.toFixed(2)
        },
      shoppingCart() {
          return window.$global.shoppingCart
      }
    },
    methods: {
        settlement() {
          if (this.selectionList.length === 0) {
            this.$message.info('无商品')
            return
          }
          this.$router.push({name: 'Order', params: {productList: this.selectionList}})
        },
        deleteItem(index) {
            this.shoppingCart.splice(index, 1)
        },
        handleSelectionChange(e) {
            this.selectionList = e
        },
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            let amount = 0

            for (let i in data) {
                amount += data[i].amount
            }
            sums[4] = '该机构小计：¥' + amount.toFixed(2);
            return sums
        }
    }
}
</script>

<style scoped>
.global-title {
    font-size: 20px;
    font-weight: bold;
}

.shopping-container {
    height: 90%;
    width: 100%;
  position: relative; /* 设置相对定位 */
  padding-bottom: 100px; /* 为底部栏预留空间 */
}

.text-small {
    color: #969696;
    font-size: 16px;
    margin: 5px 13px;
}

#totalColumn {
    height: auto;
    float: right;
    display: flex;
    margin: 20px 0;
}

#left-context {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
}

#left-context span {
    font-size: 13px;
    margin: 3px -10px;
}

/deep/ .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #DCDCDC !important;
    font-size: 20px;
    color: #373737;
}

#left-context p {
    margin: -10px 20px;
    color: red;
    font-size: 24px;
}

.bottom-span {
    font-size: 13px;
    color: #969696;
}

.jsBtn {
    width: 141px;
    height: 45px;
    float: right;
    text-align: center;
    align-content: center;
    display: flex;
    justify-content: center;
    color: white;
    line-height: 45px;
    cursor: pointer;
    box-shadow: #F2BD32 0 0 3px 0;
    /*background-image: url("@/assets/image/save.png");*/
    background: linear-gradient(90deg, #F2BD32 100%, #ED8C2C 100%);
}

</style>
