<template>
  <div class="index">
    <top-bar/>
    <transition name="transitionRouter" mode="out-in">
      <router-view id="router"/>
    </transition>
    <!--底部栏-->
    <foot-bar/>

  </div>
</template>

<script>
import * as req from "@/utils/request"
import {setToken} from "@/utils/token/jwtService";
import TopBar from "@/components/topBar.vue"
import FootBar from "@/components/footBar.vue";

export default {
  name: 'hgIndex',
  components: {FootBar, TopBar},
  watch: {
    '$route'(newVal) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }
  },
  created() {
    window.$getUserInfo = this.getUserInfo
    this.getUserInfo()
  },
  computed: {
    // 获取用户产品权限
    getUserProductList() {
      let user = window.$global.userDetails
      if (!user) return []
      return user.productExpired
    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      let sendData = {}
      sendData.data = null
      sendData.fn = this.getUserInfoFn
      sendData.vm = this
      req.Get('sysUser/getUserInfo', sendData)
    },
    getUserInfoFn(data) {
      if (!data) return
      this.queryProductList()
      if (data.code === 200) {
        let user = data.data.sysUser
        // 是否需要绑定微信公众号
        // if (!user.openid) {
        //   this.bindWx(user)
        // }
        // 教师给予学生的权限
        // let authorities = data.data.authorities
        // if (authorities) {
        //   this.$message.error(JSON.stringify(authorities))
        // }
        // 学生充值产品的有效期
        let productExpired = data.data.productExpired

        if (!productExpired) {
          productExpired = []
        }
        let addKey = {}
        for (let row of productExpired) {
          addKey[row.id] = row
        }
        data.data.productExpired = addKey
        window.$global.userDetails = data.data
      }
    },
    // 获取产品列表
    queryProductList() {
      let sendData = {}
      sendData.data = null
      sendData.fn = this.queryProductListFn
      sendData.vm = this
      req.Get('sysProduct/queryProductList', sendData)
    },
    queryProductListFn(data) {
      if (!data) return
      if (data.code === 200) {
        window.$global.ProductList = data.data
      }
    }
  }
}
</script>

<style scoped>

.index {
  width: 100vw;
  min-height: 100vh;
  position: relative;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

#router {
  padding-bottom: 150px;
}
</style>
