<template>
  <div>
    <b-container fluid id="course-details">
      <el-breadcrumb style="font-size: 20px" class="mt-4" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><span style="cursor: pointer" @click="$router.push('/Index')">
            首页
        </span></el-breadcrumb-item>
        <el-breadcrumb-item><a class="font-weight-bold text-dark">课程详情</a></el-breadcrumb-item>
      </el-breadcrumb>

      <div class="card-box p-md-5 p-3">
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="5">
            <el-image class="showImage" fil="fill" :src="getImage"></el-image>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="7">
            <div class="level-layer mt-2">
              <div style="text-align: center;margin-right: 30px;  font-weight: bold">{{ productDto.name }}</div>
            </div>

            <div class="small-learn  mt-3 d-flex text-nowrap">
              1233 人学过
              <el-rate class="ml-4" v-model="value1"></el-rate>
            </div>

            <div class="text-red mt-4" v-show="!permittedUse">
              ¥ {{ getTheAmount }}
            </div>


            <div v-if="permittedUse" style="margin-top: 100px">
              <div class="js-btn" @click="startLearning">
                开始学习
              </div>
              <div class="d-none d-md-flex" style="line-height: 45px">
                有效期: {{ expiryFormat }}
              </div>
            </div>

            <div v-else class="d-flex justify-content-end mt-2">
              <div v-if="isFree" class="js-btn" @click="freeGet">
                免费获取
              </div>
              <div v-else class="js-btn" @click="joinNow">
                立即下单
              </div>
              <div class="p-2 ml-3 addToCart d-block d-md-flex">
                <div class="d">
                  <img width="25" src="@/assets/image/900-2-1.png" alt="">
                </div>
                <div style="margin: 1px 5px;cursor: pointer"
                     v-if="!isFree"
                     @click="addToCart"
                     class="text-nowrap light-text">
                  加入购物车
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row class="pt-5">
        <div class="left-img-icon"></div>
        <div class="left-img-text">
          介绍
        </div>
      </b-row>

      <div class="m-4">
        <b-row class="p-2">
          <div class="header-title">适用人群</div>
        </b-row>

        <b-row class="p-2">
          <div class="left-img-text-small">
            本课程适合所有零基础，以及想要掌握{{ productDto.name }}的学员。
          </div>
        </b-row>
      </div>

      <el-divider></el-divider>

      <div class="m-4">
        <b-row class="p-2">
          <div class="header-title">课程概述</div>
        </b-row>

        <b-row class="p-2">
          <div class="left-img-text-small">
            本课程适合所有零基础，以及想要掌握{{ productDto.name }}的学员。
          </div>
        </b-row>

        <b-row class="p-2">
          <div class="left-img-text-small">
            【购买提示】
          </div>
        </b-row>


        <b-row class="p-2">
          <div class="left-img-text-small">
            <!--            1. 本课程为视频授课，共12课时+12课堂讲义，不提供发票；-->
            1. 本课程购买有效期为24小时，可自行安排时间进行学习。
          </div>
        </b-row>

        <!--        <b-row class="p-2">-->
        <!--          <div class="left-img-text-small">-->
        <!--            2. 本课程购买有效期为24小时，可自行安排时间进行学习。-->
        <!--          </div>-->
        <!--        </b-row>-->
      </div>

      <!--    <b-row class="mt-3">-->
      <!--      <div class="left-img-icon"></div>-->
      <!--      <div class="left-img-text">-->
      <!--        确认订单信息-->
      <!--      </div>-->
      <!--    </b-row>-->
    </b-container>
  </div>
</template>

<script>
import {getToken} from "@/utils/token/jwtService";
import * as req from "@/utils/request";

export default {
  name: "course-Details",
  data() {
    return {
      value1: 5,
      productDto: {},
      expiryDate: new Date(),
      // 当前商品 todo
      items: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'details',
          active: true
        }
      ]
    }
  },
  computed: {
    // 判断是否免费
    isFree() {
      let item = this.productDto
      return item.amount === 0 || item.amount === 0.0 || item.amount === null;
    },
    // 當前圖片
    getImage() {
      let id = this.productDto.id
      return require(`@/assets/tools/${id}.png`)
    },
    // 时间格式化
    expiryFormat() {
      if (!this.expiryDate) return ''
      const now = new Date(this.expiryDate);

      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);

      const formattedTime = year + '-' + month + '-' + day // + ' ' + hours + ':' + minutes + ':' + seconds;
      return formattedTime
    },
    // 当前用户是否有该商品的权限
    getExpiration() {
      let user = window.$global.userDetails
      if (!user) return null
      let productExpired = user.productExpired

      let commodity = this.productDto
      let productDetails = productExpired[commodity.id]
      if (!productDetails) return null
      return productDetails
    },
    // 判断当前课程是否有效期
    permittedUse() {
      let product = this.getExpiration
      // 获取商品有效时间
      if (!product || !product.expiryDate) {
        return false
      }
      // 判断有效期
      let curDate = new Date().getTime() / 1000
      let expiryDate = new Date(product.expiryDate).getTime() / 1000
      if (expiryDate <= curDate) {
        return false
      }
      return true
    },
    getTheAmount() {
      let amount = this.productDto.amount
      if (amount === undefined || amount === null) {
        return '0.00'
      }
      return amount.toFixed(2)
    },

  },
  created() {
    let params = this.$route.params.product
    if (!params) {
      this.$router.push('/index')
      return
    }
    this.productDto = params
    let product = this.getExpiration
    if (product) {
      this.expiryDate = new Date(product.expiryDate).toLocaleString()
    }
  },
  methods: {
    addToCart() {
      let commodity = JSON.parse(JSON.stringify(this.productDto))
      // todo 这里的购物车应该由后端处理
      let tempState = false
      window.$global.shoppingCart.filter((item) => {
        if (item.id === commodity.id) {
          tempState = true
          return
        }
      })

      if (tempState) {
        this.$message.error('该商品已存在购物车')
        return
      }
      window.$global.shoppingCart.push(commodity)
      this.$message.success('加入成功')
    },
    // 免费获取
    freeGet () {
        let sendData = {}
        let obj = {}
      obj.id = this.productDto.id
        sendData.data = obj
        sendData.fn = this.freeFn
        sendData.vm = this
        req.Post('wxPay/free', sendData)
    },

    freeFn(data) {
      if (!data) return
      if (data.code === 200) {
        this.$message.info(data.message)
        this.$router.push('/student')
        return;
      }
      this.$message.error(data.message)
    },
    joinNow() {
      let row = this.productDto
      // 确认订单数据
      this.$router.push({name: 'Order', params: {productList: [row]}})
    },
    startLearning() {
      let product = this.getExpiration
      if (!product) {
        return;
      }

      // 判断有效期
      if (!product.expiryDate) {
        this.$message.error('有效时间不足,请续费')
        return
      }
      let curDate = new Date().getTime() / 1000
      let expiryDate = new Date(product.expiryDate).getTime() / 1000
      if (expiryDate <= curDate) {
        this.$notify({
          title: '有效时间不足',
          dangerouslyUseHTMLString: true,
          message: '<strong>请续费</strong>',
          onClick: (() => {
            // this.productsShow = true
          })
        })
        return
      }

      // 注意 教师权限的优先级
      if (!product.permissions) {
        this.$message.error('教师未给予该学生权限')
        return;
      }
      let jwt = getToken()
      if (!jwt) {
        this.$router.push('/login')
        return;
      }
      window.open(product.url + '?token=' + jwt, '_blank');
    }
  }
}
</script>

<style scoped>
#course-details {
  height: 100%;
  width: 60%;
}


.card-box {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.20) 0 0 4px 0px;

}

.level-layer {
  width: 206px;
  height: 40px;
  background-color: transparent;
  background-image: url("@/assets/image/rectangle-blue.png");
  /*text-align: center;*/
  line-height: 40px;
  color: white;
  border: none;
  font-size: 17px;
}

.small-learn {
  font-size: .980em;
  color: rgba(179, 179, 179, 1);
  font-family: 等线;
  font-weight: bold;
}

.text-red {
  color: red;
  font-size: 50px;
  font-weight: bold;
}

.js-btn {
  width: 113px;
  height: 49px;
  float: right;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  color: white;
  line-height: 45px;
  font-family: 微软雅黑;
  cursor: pointer;
  font-size: 16px;
  background-image: url("@/assets/image/golden.png");
}

.addToCart {
  color: rgba(0, 117, 230, 1);
  font-family: 微软雅黑;
}


.left-img-icon {
  width: 23px;
  height: 23px;
  background-size: 100%;
  margin: 7px 15px;
  background-image: url("@/assets/image/left-img-icon.png");
}

.left-img-text {
  padding: 0 5px;
  font-weight: bold;
  font-size: 25px;
  display: flex;
}


.left-img-text-small {
  color: #9E9E9E;
  font-size: 16px;
}

.header-title {
  color: #171717;
  font-weight: bolder;
  font-size: 16px;
}

.breadcrumb {
  font-size: 20px;
}

.breadcrumba {
  font-weight: bold;
  font-size: 25px;
}

.light-text:hover {
  /*color: #40d1d7;*/
}

.showImage {
  border-radius: 18px;
}
</style>
