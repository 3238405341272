<script>
export default {
  name: 'hgHome',
  data () {
    return {
      urlList: []
    }
  },
  computed: {
    // 获取用户产品权限
    getUserProductList () {
      let user = window.$global.userDetails
      if (!user) return []
      return user.productExpired
    },
    // 获取产品列表
    getProductList () {
      return window.$global.ProductList
    }
  },

  created () {
    this.urlList.push(require('@/assets/image/carousel-1.png'))
    this.urlList.push(require('@/assets/image/carousel-1.png'))
  },
  methods: {
    getImage (id) {
      return {
        backgroundImage: `url(${require(`@/assets/tools/${id}.png`)})`
      };
    },
    // 跳至产品页面
    skipToProductPage (item) {
      this.$router.push({ name: 'details', params: { product: item } })
    }
  }
}
</script>

<template>
  <div>
    <el-carousel height="600px">
      <el-carousel-item v-for="(item, index) in urlList" :key="index">
        <img :src="item" alt="暂无图片">
      </el-carousel-item>
    </el-carousel>

    <el-container direction="vertical" class="home-container">
      <!--系列学习工具-->
      <div class="center-box">
        <div class="rectangle"></div>
        <div class="learningTools"></div>
        <div class="rectangle"></div>
      </div>
      <div class="collapseItem d-flex justify-content-md-center
           justify-content-sm-start justify-content-lg-start
            justify-content-center" style="width: 70%">

        <div style="cursor: pointer" v-for="(item, index) in getProductList" :key="index">
          <div class="home-tools-img" :style="getImage(item.id)" @click="skipToProductPage(item)">
            <div class="home-tools-list-item">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>

        <!--        填充空白区域-->
        <div v-for="item in 1" :key="item">
          <div class="home-tools-img-no">
          </div>
        </div>
      </div>

      <!--  关于我们    -->
      <div class="center-box">
        <div class="rectangle mr-2"></div>
        <div class="abouus"></div>
        <div class="rectangle ml-2"></div>
      </div>


      <div class="aboutUs justify-content-lg-between justify-content-md-between justify-content-sm-center
          d-block d-md-block d-sm-block d-lg-flex">
        <el-image class="companyPresentation" :src="require('@/assets/image/companyPresentation.png')"
          fit="fill"></el-image>
        <div class="text-infos ml-lg-5 mt-3">
          <p style="text-indent: 40px;letter-spacing: 3px;font-family: 等线">

            厦门泓庚航海科技有限公司（简称泓庚航海）自2011年成立以来在航海电子领域不断深化，产品包括航海设备仿真，船电工程，IBS系统集成，VTS监控系统，信息系统集成等，并逐渐形成一套完整的设计、安装、调试、培训、维护一站式服务体系，获得国家软件企业认定证书、多项实用新型专利证书及软件产品登记证书，在航海电子领域奠定了坚实的行业地位。
          </p>
          <p style="text-indent: 40px;letter-spacing: 3px;font-family: 等线">
            泓庚航海拥有专业的技术团队，高效的策划团队，卓越的业务团队，资深的培训团队，优秀的服务团队。各部门在工作中团结协作，不断超越自我，成为公司稳步发展的保障，经过不断探索和大胆创新，公司已经逐步形成了完整的具有自主知识产权的航海电子系列产品。产品按低、中、高分级配置，可以满足客户的多层次需求，在行业内，泓庚航海的产品在技术含量和配套完整性方面均居于前列。
          </p>

        </div>
      </div>


    </el-container>

  </div>
</template>

<style scoped>
.rectangle {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 61px;
  height: 3px;
  background-image: url("@/assets/image/rectangle.png");
}

.abouus {
  width: 140px;
  height: 55px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("@/assets/image/abouus.png");
}

.center-box {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  margin: 30px auto;
}

.home-tools-img-no {
  max-width: 429px;
  max-height: 245px;
  width: 429px;
  height: 245px;
  margin: 5px 2px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-image: url("@/assets/image/toBeDeveloped.png");
  -webkit-filter: grayscale(80%) opacity(90%);
  filter: grayscale(80%) opacity(90%);
}


.home-tools-img {
  max-width: 429px;
  max-height: 245px;
  width: 429px;
  height: 245px;
  margin: 5px 2px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 18px;
  align-items: center;
}

.home-tools-list-item {
  width: 320px;
  height: 121px;
  background-image: url("@/assets/image/rectangle-border.png");
  color: white;
  font-size: 47px;
  line-height: 110px;
  font-weight: bold;
  font-family: 微软雅黑;
  cursor: pointer;
  text-align: center;
}

.companyPresentation {
  max-width: 429px;
  display: block;
  max-height: 398px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.learningTools {
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("@/assets/image/learningTools.png");
  width: 216px;
  height: 53px;
}

.aboutUs {
  width: 70%;
  align-items: center;
  text-align: left;
  margin: 0 auto;
}

.collapseItem {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.text-infos {
  max-width: 800px;
  width: 100%;
}

.home-container {
  width: 100%;
  margin: 0 auto;
  align-items: center
}
</style>
