<template>
  <div class="login-box">
    <div id="container">
      <div class="box">
        <!-- 头部 -->
        <el-row class="header-row" type="flex" justify="center">
          <el-col :span="9" @click="mode = 'phone'" class="pointer" :class="mode === 'phone' ? 'light':''">
            <div>
              <span @click="handoffTab">
                {{ mode === 'register' ? '手机账号登录' : '手机账号登录' }}
              </span>
              <div v-if="['phone'].includes(mode)" class="tab-line">&nbsp;</div>
              <div v-else class="tab-line-none">&nbsp;</div>
            </div>
          </el-col>
          <el-col :span="2">
            <el-divider direction="vertical"></el-divider>
          </el-col>
          <el-col :span="9" class="pointer" :class="mode === 'wechat' ? 'light':''">
            <div>
              <span @click="mode = 'wechat'">微信快捷登录</span>
              <div v-if="mode === 'wechat'" class="tab-line">&nbsp;</div>
              <div v-else class="tab-line-none">&nbsp;</div>
            </div>
          </el-col>
        </el-row>

        <!-- 手机号登录 -->
        <transition name="el-fade-in-linear">
          <phone-login class="row-move-down" v-show="mode === 'phone'" v-if="mode === 'phone'">
            <el-row style="margin: 20px auto" type="flex" justify="space-between">
              <el-col :span="9">
                <span class="elspan pointer">没有账号?</span>
                <span class="elspan2 pointer" @click="mode = 'register'"> 去注册</span>
              </el-col>
              <el-col class="pointer" :span="5">
                <span @click="mode = 'retrievePassword'">忘记密码</span>
              </el-col>
            </el-row>
          </phone-login>
        </transition>

        <!-- 注册 -->
        <registered-users class="row-move-down" ref="zc" v-show="mode === 'register'">
          <el-row style="margin: 20px auto" type="flex">
            <el-col :span="9">
              <span class="elspan pointer">已有账号?</span>
              <span class="elspan2 pointer" @click="mode = 'phone'"> 去登录</span>
            </el-col>
          </el-row>
        </registered-users>

        <!-- 找回密码 -->
        <retrieve-your-password class="row-move-down" style="margin: 40px auto"
                                v-if="mode === 'retrievePassword'">
        </retrieve-your-password>

        <section class="wechat-qr" v-show="mode === 'wechat'">
          <div id="weixinLogin">
          </div>
        </section>
      </div>
    </div>

    <div class="text-box" :style="offsetText" v-if="width >=1065">
      <h2>Welcome</h2>
      <div class="tab-line" style="margin: 4px 0px;width: 18px">&nbsp;</div>
      <div>Hong Geng</div>
      <!-- <div>舰操绘算</div> -->
    </div>

    <!-- 备案号 -->
    <div class="code">
      闽ICP备15011792号-3
    </div>
  </div>
</template>


<script>
import * as req from "@/utils/request";
import RetrieveYourPassword from "@/components/fromCom/retrieveYourPassword.vue";
import RegisteredUsers from "@/components/fromCom/registeredUsers.vue";
import PhoneLogin from "@/components/fromCom/phoneLogin.vue";
import {setToken} from "@/utils/token/jwtService";
export default {
  name: "loginBox",
  components: {
    PhoneLogin,
    RegisteredUsers,
    RetrieveYourPassword
  },
  data() {
    return {
      mode: 'phone',
      width: '1920',
      height: '1080',
      ws: null
    }
  },
  watch: {
    'mode'(val) {
      if (val === 'wechat') {
        this.wxLogin()
      }

      if (val === 'phone') {
        if (!this.$refs['zc']) return
        // this.$refs['zc'].form = {
        //   phone: null,
        //   password: null,
        //   openid: null,
        //   code: null,
        //   uuid: null
        // }
      }
    }
  },
// # 监听 只要有变化就调用resizeHandler函数
  beforeMount() {
    window.addEventListener('resize', this.resizeHandler)
  },
// # 卸载
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: {
    offsetText() {
      const viewportWidth = this.width
      let value = (viewportWidth / 100) * 25 + 'px';
      return `left: ${value};transition: all .3s linear`
    },
    getOffset() {
      // 获取当前页面的视口宽度
      const viewportWidth = this.width
      if (viewportWidth <= 600) {
        return '485PX'
      }
      return (viewportWidth / 100) * 85 + 'px';
    }
  },
  mounted() {
    this.resizeHandler()
    // 检查 是否是注册模式
    let result = this.isRegistere()
    if (!result) {
      return
    }
    // 注册模式
    console.log(result)
    if (result.uuid) {
      this.uuid = result.uuid
      setTimeout(() => {
        this.mode = 'register'
        this.$refs['zc'].form.uuid = result.uuid
        console.log('code: ', result.uuid)
      }, 500)
      return;
    }

    // 微信重定向后开始调用登录
    if (result.login) {
      let sendData = {}
      sendData.data = {uuid: result.login}
      sendData.fn = this._weChatLoginFn
      sendData.vm = this
      req.Post('wechat/login', sendData)
    }

  },
  methods: {
    // 微信重定向后开始调用登录
    _weChatLoginFn(data) {
      // console.log('微信重定向后开始调用登录', data)
      if (!data) {
        this.$message.error(data.message)
        return;
      }
      if (data.code === 200) {
        setToken(data.data)
        this.$router.push('/index')
        return
      }
      if (data.code === 600) {
        const h = this.$createElement;

        this.$notify({
          title: '提示',
          message: h('i', {style: ''}, data.message)
        });
        return;
      }
      window.$message.error(data.message)
    },
    // 生成uuid
    getUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    // 连接netty
    connectToAServer (uuid) {
      if (this.ws) {
        this.ws.closeNetty()
      }
      // this.ws = new netty(uuid)
      //     .createWebSocket((data) => {
      //       if (data.code === 200) {
      //         console.log(data)
      //       }
      //       if (data.code === 5004310) {
      //         this.$message.error(data.message)
      //       }
      //     })
    },
    handoffTab() {
      // if (this.mode === 'phone') {
      this.mode = 'phone'
      //   return
      // }
      // this.mode = 'register'
    },
    // 注册和登录 都依靠 微信重定向
    isRegistere() {
      const url = location.hash;
      if (url.indexOf('?') === -1) {
        return null
      }
      let obj = url.split('?');
      let val = '{'
      if (obj[1].indexOf('&') !== -1) {
        obj = obj[1].split('&')
      }
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].indexOf('=') !== -1) {
          val += '"' + obj[i].replace(/=/g, '":"') + '",'
        }
      }
      val = val.substring(0, val.length - 1)
      val += '}'
      let result = {}
      try {
        result = JSON.parse(val)
      } catch (e) {
        return null
      }

      this.hideLinkParameters()
      return result
    },
    resizeHandler() {
      // 获取宽高
      const rect = document.body.getBoundingClientRect()
      let winSize = {
        height: rect.height,
        width: rect.width
      }
      this.width = rect.width
    },
    // 获取微信登录二维码
    wxLogin() {
      let sendData = {}
      sendData.data = null
      sendData.fn = this._wxLoginFn
      sendData.vm = this
      req.Get('wechat/qr/login', sendData)
    },
    _wxLoginFn(data) {
      if (!data) {
        this.$message.error('网络连接失败 请稍后重试')
        return
      }
      if (data.code === 200) {
        let uuid = data.data.state
        // eslint-disable-next-line no-undef
        new WxLogin({
          self_redirect: false,
          id: 'weixinLogin', // 需要显示的容器id
          appid: data.data.appid, // 公众号appid wx*******
          scope: data.data.scope, // 网页默认即可
          redirect_uri: data.data.redirect_uri, // 授权成功后回调的url
          state: data.data.state, // 可设置为简单的随机数加session用来校验
          style: 'black', // 提供"black"、"white"可选。二维码的样式
          href: '' // 外部css文件url，需要https
        })
        return
      }
      this.$message.error(data.message)
    },
    // 获取验证码
    sendSms(form) {
      let sendData = {}
      sendData.data = form
      sendData.fn = this._sendSmsFn
      sendData.vm = this
      req.Post('code/verify', sendData)
    },
    _sendSmsFn(data) {
      if (!data) return
      if (data.code === 200) {
        this.$message.success('发送成功')
        window.$startTimer()
        return;
      }
      this.$message.error(data.message)
    },
    // 隐藏链接参数
    hideLinkParameters() {
      let url = window.location.href;
      let reg = /[^\w](token|code|state|uuid|login)=?([^&|^#]*)/g;
      url = url.replace(reg, "");
      reg = /&&/g;
      url = url.replace(reg, "");
      reg = /&#/g;
      url = url.replace(reg, "#");
      reg = /\?#/g;
      url = url.replace(reg, "#")
      reg = /\?#/g;
      url = url.replace(reg, "#")
      window.history.replaceState(null, null, url);
    },
  }
}
</script>

<style scoped>
@import "@/assets/from.css";

.login-box {
  width: 100%;
  height: 100%;
  display: flex;
  background-position: center;
  justify-content: center;
  text-align: center;
  font-family: 微软雅黑;
  background-repeat: no-repeat;
  background-color: #EBF3FF;
  background-image: url("@/assets/image/background.jpg");
}

#container {
  background-image: url("@/assets/image/box.png");
  background-position: center;
  justify-content: center;
  display: flex;
  width: 476px;
  height: 52.5%;
  min-height: 470px;
  background-size: 90%;
  background-repeat: no-repeat;
  position: absolute;
  right: calc(100vw - v-bind(getOffset));
  top: 15vh;
  bottom: 0;
  transition: all .3s linear;
}

.wechat-qr {
  overflow: hidden;
  width: 100%;
  height: 92%;
  min-height: 330px;
}

.tab-line {
  width: 40px;
  background-color: #4074E1;
  height: 2.5px;
  border-radius: 12px;
  margin: 6px auto;
  box-shadow: #4074E1 0 0px 30px 0px;
  transition: all .1s linear;
}

.tab-line-none {
  width: 40px;
  height: 2.5px;
  margin: 6px auto;
  transition: all .1s linear;
}

.text-box {
  width: 250PX;
  height: 250PX;
  transition: all .3s linear;
  position: absolute;
  top: 28vh;
  bottom: 0;
  text-align: left;
  color: #4074E1;
  letter-spacing: 1px;
}

.notRegistered {
  text-align: left;
  margin: 5px 0;
  font-size: 13px;
  padding-left: 23px;
  color: #606266;
}
.code {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
