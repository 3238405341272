import axios from 'axios'

const qs = require("qs");

let axiosConfig = {
    baseURL: process.env.VUE_APP_API_URL
}


axios.defaults.withCredentials = true
axios.defaults.headers = true
let server = axios.create(axiosConfig)
// 添加请求拦截器
server.interceptors.request.use(function (config) {
    let token = window.localStorage.getItem("Authorization");
    config.headers = {
        // "Content-Type": "application/json;charset=utf-8",
    }
    if (token) {
        config.headers['Authorization'] = token
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 发送Post数据
function Post(type, msg) {
    // 要发送的数据
    let param = msg.data
    // 接收返回的回调函数
    let fn = msg.fn
    // 回调函数运行的this指针
    let vm = msg.vm

    let url = type

    // if (url.indexOf('doLogin') !== -1) {
    //     let sendData = qs.stringify(param)
    //     sendData = new FormData();
    //     for (let i in param) {
    //         if (param[i]) {
    //             sendData.append(i, param[i])
    //         }
    //     }
    //     param = sendData
    // }

    // if (type == 'wechat/login') {
    //     let sendData = qs.stringify(param)
    //     sendData = new FormData();
    //     sendData.append('uuid', param.uuid);
    //     param = sendData
    // }
    server.post(url, param)
        .then((res) => {
            //  服务器响应数据在这里面
            let resdata = res.data
            // console.debug('post: ', resdata)
            if (fn === null || fn === undefined) {
                return
            }
            if (resdata.code === 401) {
                window.$message.error(resdata.message)
                window.$router.push('/login')
                return;
            }
            fn.call(vm, resdata)
        })
        .catch((e) => {
            //  服务器响应数据在这里面
            if (fn === null || fn === undefined) {
                return
            }
            console.error('post: ', e)
            if (e.message === 'Network Error') {
                fn.call(vm, null)
                return;
            }

            let res = e.response
            if (!res) return;
            fn.call(vm, res.data)
        })
}

// 发送Get数据
function Get(type, msg) {
    // 要发送的数据
    let param = msg.data
    // 接收返回的回调函数
    let fn = msg.fn
    // 回调函数运行的this指针
    let vm = msg.vm

    // let config = {
    //   params: param
    // }

    let url = type

    server.get(url, param)
        .then((res) => {
            if (fn === null || fn === undefined) {
                return
            }
            let resdata = res.data
            if (resdata.code === 401) {
                window.$message.error(resdata.message)
                window.$router.push('/login')
                return;
            }
            fn.call(vm, resdata)
        })
        .catch((e) => {
            //  服务器响应数据在这里面
            if (fn === null || fn === undefined) {
                return
            }
            console.error('get: ', e)
            if (e.message === 'Network Error') {
                fn.call(vm, null)
                return;
            }
            let res = e.response
            if (!res) return;
            fn.call(vm, res.data)
        })
}

export {
    server,
    Get,
    Post
}
