export default class hgWebSocket {
    constructor(uid) {
        this.uid = uid
        // this.ws = 'ws://localhost:4322/ws/'
        this.ws = window.$ws === undefined ? 'wss://www.xmhgapi.com/wechat/' : window.$ws
        // 避免重复连接
        this.lockReconnect = false
        // 是否连接成功
        this.isConnect = false
        this.webSocket = null
        // 解析网络协议函数列表
        this.resFn = null
    }

    createWebSocket(Fn) {
        if ([undefined, null].includes(this.uid)) {
            console.error('无 userID')
            return null
        }
        if (!Fn) {
            console.error('未设置回调函数')
            return
        }
        this.resFn = Fn
        try {
            this.webSocket = new WebSocket(this.ws + `?uid=${this.uid}`)
            this.webSocket.onopen = this.onOpen
            this.webSocket.onerror = this.onError
            this.webSocket.onclose = this.onClose
            this.webSocket.onmessage = this.onMessage
            this.webSocket.closeNetty = this.closeNetty
            this.webSocket.parent = this
            return this.webSocket
        } catch (e) {
            this.reconnect()
            return null
        }
    }


    onOpen(message) {
        let _this = this.parent
        _this.isConnect = true
    }

    // 服务器出错
    onError(message) {
        let _this = this.parent
        _this.isConnect = false
        _this.reconnect()
    }

    // 服务器关闭
    onClose(message) {
        let _this = this.parent
        _this.isConnect = false
        // console.log(message)
        _this.reconnect()
    }

    // 断线重连
    reconnect() {
        // 没连接上会一直重连，设置延迟避免请求过多
        if (this.lockReconnect) {
            return
        }
        let _this = this
        setTimeout(function () {
            // _this.createWebSocket()
            // _this.lockReconnect = false
        }, 2000)
        this.lockReconnect = true
    }

    // 接收处理
    onMessage(message) {
        let data = message.data
        let _this = this.parent
        try {
            data = JSON.parse(data)
            _this.resFn(data)
        } catch (e) {
            console.error(e)
            return
        }

    }

    closeNetty() {
        let _this = this.parent
        if (_this.webSocket) {
            _this.webSocket.close()
        }
    }


    send(data) {
        if (!this.isConnect) {
            // console.info('网络未连接')
            this.reconnect()
            return
        }
        console.log(data, 'sendData')
        this.webSocket.send(data)
    }
}
