<template>
  <div id="foot-bar" class="text-light pt-3">
    <b-container>
      <b-row class="justify-content-center justify-content-lg-start">
        <b-col lg="4" md="7" cols="10" sm="12">
          <b-row>
            <!-- <b-col class="upperLevelText">关于我们</b-col> -->
            <b-col class="upperLevelText">联系我们：18906019743</b-col>
            <!-- <b-col class="upperLevelText">帮助中心</b-col> -->
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8" md="7" cols="12" sm="12"
          class="justify-content-center justify-content-md-start d-flex small-text text-left">
          厦门泓庚航海科技 版权所有 闽ICP备15011792号-3
        </b-col>

        <b-col lg="2" md="4" cols="12" sm="12" class="justify-content-center small-text d-flex">
          关注我们:
          <div @click="wechat">
            <el-avatar id="avatar-wechat" shape="square" size="small"
              :src="require('@/assets/image/wechat.png')"></el-avatar>
          </div>
          <!-- <div @click="weibo">
            <el-avatar id="avatar-weibo" shape="square" size="small"
              :src="require('@/assets/image/weibo.png')"></el-avatar>
          </div> -->
        </b-col>
      </b-row>
    </b-container>

    <el-dialog :show-close="false" width="468px" title="绑定微信公众号" :visible.sync="bindWeChat">
      <img :src="bindWxQR">
      <div slot="footer" class="dialog-footer">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as req from "@/utils/request";
import netty from "@/utils/websocket";

export default {
  name: "footBar",
  data () {
    return {
      bindWeChat: false,
      bindWxQR: null
    }
  },
  methods: {
    wechat () {
      // let user = window.$global.userDetails.sysUser
      // this.bindWx(user)
    },
    weibo () {
      window.location.href = 'https://weibo.com/'
    },
    // 首次登录 需要绑定微信
    bindWx (user) {
      let sendData = {}
      sendData.data = user
      sendData.fn = this._bindWxFn
      sendData.vm = this
      req.Post('wechat/qr/concern', sendData)
    },
    _bindWxFn (data) {
      if (data.code !== 200) {
        this.$message.error(data.message)
        return
      }
      let userId = window.$global.userDetails.sysUser.id
      this.bindWeChat = true
      this.bindWxQR = data.data.QRUrl
      this.ws = new netty(userId)
        .createWebSocket((data) => {
          this.bindWeChat = false
          this.bindWxQR = null
          this.$message.success("绑定成功, 下次可用于扫码登录！")
          this.ws.closeNetty()
        })

    },
  }
}
</script>

<style scoped>
#foot-bar {
  background: #272727;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  /* 使用绝对定位 */
  bottom: 0;
  /* 固定至底部 */
  left: 0;
  /* 左右居中 */
  color: white;
  /* 文字颜色 */
}

.small-text {
  font-size: .93em;
  color: rgba(255, 255, 255, 0.78);
}

.upperLevelText {
  font-size: .95em;
  cursor: pointer;
}

#avatar-wechat {
  position: relative;
  left: 5px;
  bottom: 4PX;
  cursor: pointer;
}

#avatar-weibo {
  position: relative;
  left: 15px;
  bottom: 4PX;
  cursor: pointer;
}
</style>
