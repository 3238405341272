<template>
  <div id="student-container">
    <div class="bannan h-50 d-none d-md-block">
      <div class="p-4 w-50 m-auto d-flex">
        <el-image
            class="avatar-max"
            fit="fill"
            :src="getHeadImg">
        </el-image>

        <div class="p-1 ml-2">
          <div class="nickname-row">{{ getUserName }}, {{ getTimeState }}</div>
          <div class="stay-row">stay hungry,stay foolish</div>
        </div>
      </div>
    </div>
    <b-container>
      <div class="position-relative p-3">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程" name="course">

            <b-row>
              <b-col cols="12" md="6" sm="6" xl="3" class="student-tools"
                     v-for="(item, index) in getCourseList"
                     :key="index">

                <div class="card m-1 d-block shadow pointer" @click="startLearning(index)">
                  <div class="position-relative">
                    <div class="d-flex text-center m-auto">
                      <el-image fit="none"
                                :src="require(`@/assets/tools/${item.id}.png`)"></el-image>
                      <span class="card-img-center mt-5 d-none d-md-none d-lg-none d-sm-none d-xl-block">
                        {{ item.name }}</span>
                    </div>
<!--                    <div class="img-in-border">-->
<!--                      【学习进度】0%-->
<!--                    </div>-->
                  </div>
                  <div class="card-footer bg-light">
                    <b-card-text class="d-block">
                                                                  <el-button v-if="whetherItHasExpired(item)" @click="renewal(item)"
                                                                             size="small" type="warning"
                                                                             class="m-auto d-flex">续费
                                                                  </el-button>
                      <!--                                            <el-button v-else size="small" @click="startLearning(index)" type="primary"-->
                      <!--                                                       class="m-auto d-flex">-->
                      <!--                                                开始学习-->
                      <!--                                            </el-button>-->
                      <div v-if="item.expiryDate"
                           class="small text-muted d-flex justify-content-center pt-2 text-nowrap"><span
                          class="">
                        课程有效时间:
                      </span>
                        {{ expiryFormat(item.expiryDate) }}
                      </div>
                    </b-card-text>

                  </div>
                </div>
              </b-col>
            </b-row>

          </el-tab-pane>
          <el-tab-pane label="收藏夹" name="favorites">

            <b-row>
              <b-col cols="12" md="6" sm="6" xl="3" class="student-tools"
                     v-for="(item, index) in getCourseList2"
                     :key="index">

                <div class="card m-1 d-block shadow pointer" @click="startLearning(index)">
                  <div class="position-relative">
                    <div class="d-flex text-center m-auto">
                      <el-image fit="none"
                                :src="require(`@/assets/tools/${item.id}.png`)"></el-image>
                      <span class="card-img-center mt-5 d-none d-md-none d-lg-none d-sm-none d-xl-block">
                        {{ item.name }}</span>
                    </div>
<!--                    <div class="img-in-border">-->
<!--                      【学习进度】0%-->
<!--                    </div>-->
                  </div>
                  <div class="card-footer bg-light">
                    <b-card-text class="d-block">
                      <el-button v-if="whetherItHasExpired(item)" @click="renewal(item)"
                                 size="small" type="warning"
                                 class="m-auto d-flex">续费
                      </el-button>
<!--                      <el-button v-else size="small" @click="startLearning(index)" type="primary"-->
<!--                                 class="m-auto d-flex">-->
<!--                        开始学习-->
<!--                      </el-button>-->
                      <div v-if="item.expiryDate"
                           class="small text-muted d-flex justify-content-center pt-2 text-nowrap"><span
                          class="">
                        课程有效时间:
                      </span>
                        {{ expiryFormat(item.expiryDate) }}
                      </div>
                    </b-card-text>
                  </div>
                </div>
              </b-col>
            </b-row>

          </el-tab-pane>
        </el-tabs>
        <div class="search-tab-right">
          <el-input class="search-tab-input" v-model="like" placeholder="搜索我加入的课程" size="small"
                    suffix-icon="el-icon-search"></el-input>
        </div>
      </div>

    </b-container>
  </div>
</template>

<script>
import {getToken} from "@/utils/token/jwtService";
import * as req from "@/utils/request";

export default {
  name: "myStudies",
  data() {
    return {
      activeName: 'course',
      like: null,
    }
  },
  created() {
    // this.getUserInfo()
    window.$getUserInfo()
  },
  computed: {
    userData() {
      let sysuser = window.$global.userDetails

      if (!sysuser) return null

      return sysuser.sysUser
    },
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let state = ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        state = `早上好!`;
      } else if (hours > 10 && hours <= 11) {
        state = `上午好!`;
      } else if (hours > 11 && hours <= 13) {
        state = `中午好!`;
      } else if (hours > 13 && hours <= 18) {
        state = `下午好!`;
      } else if (hours > 18 && hours <= 24) {
        state = `晚上好!`;
      }
      return state
    },
    getUserName() {
      if (!this.userData) {
        return '未登录'
      }
      return this.userData.nickname
    },
    getHeadImg() {
      let user = this.userData
      if (!user) {
        return 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      }

      if (!user.headImg) {
        return 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      }
      return user.headImg
    },
    getCourseList() {
      let product = this.getUserProductList
      if (!this.like) {
        return product
      }
      return product.filter((p) => {
        return p.name.indexOf(this.like) !== -1
      })
    },
    // 获取用户产品权限
    getUserProductList() {
      let user = window.$global.userDetails
      if (!user) return []
      return user.productExpired
    },
    getCourseList2() {
      let res = []
      if (this.getUserProductList.length === 0) return []
      let one = this.getUserProductList[0]
      // res.push(one)

      let product = res
      if (!this.like) {
        return product
      }
      return product.filter((p) => {
        return p.name.indexOf(this.like) !== -1
      })
    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      let sendData = {}
      sendData.data = null
      sendData.fn = this.getUserInfoFn
      sendData.vm = this
      req.Get('sysUser/getUserInfo', sendData)
    },
    getUserInfoFn(data) {
      if (!data) return
      this.queryProductList()
      if (data.code === 200) {
        let user = data.data.sysUser
        // 是否需要绑定微信公众号
        // if (!user.openid) {
        //   this.bindWx(user)
        // }

        // 学生充值产品的有效期
        let productExpired = data.data.productExpired

        if (!productExpired) {
          productExpired = []
        }
        let addKey = {}
        for (let row of productExpired) {
          addKey[row.id] = row
        }
        data.data.productExpired = addKey
        window.$global.userDetails = data.data
      }
    },
    // 获取产品列表
    queryProductList() {
      let sendData = {}
      sendData.data = null
      sendData.fn = this.queryProductListFn
      sendData.vm = this
      req.Get('sysProduct/queryProductList', sendData)
    },
    queryProductListFn(data) {
      if (!data) return
      if (data.code === 200) {
        window.$global.ProductList = data.data
      }
    },
    // 时间格式化
    expiryFormat(data) {
      if (!data) return '-'
      const now = new Date(data);

      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);

      const formattedTime = year + '-' + month + '-' + day // + ' ' + hours + ':' + minutes + ':' + seconds;
      return formattedTime
    },
    // 判断是否免费
    isFree(amount) {
      return amount === 0 || amount === 0.0 || amount === null;
    },
    renewal(item) {
      let procudt = window.$global.ProductList
      for (let i in procudt) {
        if (procudt[i].id === item.id) {
          let free = this.isFree(procudt[i].amount)
          if (free) {
            this.$router.push({name: 'details', params: {product: procudt[i]}})
            return;
          }
          this.$router.push({name: 'Order', params: {productList: [procudt[i]]}})
          return
        }
      }
      this.$message.error('未找到该商品')
    },
    whetherItHasExpired(product) {
      // 判断有效期
      if (!product.expiryDate) {
        return true
      }
      let curDate = new Date().getTime() / 1000
      let expiryDate = new Date(product.expiryDate).getTime() / 1000
      if (expiryDate <= curDate) {
        return true
      }
      return false
    },
    handleClick(e) {
      console.log(e)
    },
    startLearning(index) {
      let productExpired = window.$global.userDetails.productExpired
      let product = productExpired[index]
      // 判断有效期
      if (!product.expiryDate) {
        this.$message.error('有效时间不足 请充值')
        return
      }
      let curDate = new Date().getTime() / 1000
      let expiryDate = new Date(product.expiryDate).getTime() / 1000
      if (expiryDate <= curDate) {
        this.$notify({
          title: '有效时间不足 请充值',
          dangerouslyUseHTMLString: true,
          message: '<strong>请充值</strong>',
          position: 'top-right',
          onClick: (() => {
            // this.productsShow = true
          })
        })
        return
      }
      // 注意 教师权限的优先级
      if (!product.permissions) {
        this.$message.error('教师未给予该学生权限')
        return;
      }
      let jwt = getToken()
      if (!jwt) {
        this.$router.push('/login')
        return;
      }
      // window.location.href = product.url + '?token=' + jwt
      window.open(product.url + '?token=' + jwt, '_blank');
    }
  }
}
</script>

<style scoped>
#student-container {
  width: 100%;
  height: 100%;
}

.search-tab-right {
  width: 20%;
  position: absolute;
  min-width: 180px;
  right: 40px;
  padding: 15px;
  top: 0;
}

/deep/ .search-tab-input {
  border-radius: 95px;
}

::v-deep .el-input__inner {
  border-radius: 23px;
  height: 50px;
}

::v-deep .el-tabs__nav-wrap::after {
  /*margin-top: 10px;*/
}

.student-tools {
  box-shadow: rgba(2, 2, 2, 0.84) 0 0 0px 0;
}

.img-in-border {
  background-color: rgba(101, 119, 157, 0.56);
  text-align: center;
  color: white;
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  line-height: 40px;
}

.card-img-center {
  position: absolute;
  left: 0;
  right: 0;
  align-content: center;
  /*top: 65px;*/
  color: white;
  font-size: 30px;
  font-weight: bold;
}


.bannan {
  width: 100%;
  max-height: 250px;
  height: 250px;
  background-color: rgba(0, 117, 230, 30%);
  transition: all .2s linear;
}

/deep/ .el-image-viewer__wrapper .el-image-viewer__canvas .img {
  transform: scale(0.6) rotate(0deg)
}

.nickname-row {
  font-size: 20px;
  font-family: 黑体;
  font-weight: bold;
  margin-top: 3px;
  white-space: nowrap;
}

.stay-row {
  font-size: 12px;
  font-family: 微软雅黑;
  text-transform: uppercase;
  white-space: nowrap;
}

.avatar-max {
  min-height: 70px;
  min-width: 70px;
  height: 70px;
  width: 70px;
  border-radius: 6px;
  box-shadow: #b6caf8 0 0 10px 0;
  transition: all .2s linear;
}

.avatar-max:hover {
  border-radius: 12px;
  box-shadow: #a7bff8 0 5px 20px 0;
  transition: all .2s linear;
}
</style>
